<template>
    <p>Reaction time: {{ score }} ms</p>
    <p class="rank">{{ rank }}</p>
</template>

<script>
export default {
    props : [ 'score' ],
    data() {
        return {
            rank: null,
        }
    },
    mounted() {
        if (this.score < 250) {
            this.rank = "Un vrai ninja!";
        } else if (this.score < 400 ) {
            this.rank = "Bons réflexes";
        } else {
            this.rank = "OK...ça va sinon?";
        }
    }
}
</script>

<style>
    .rank {
        font-size: 1.4rem;
        color: #0faf87;
        font-weight: bold;
    }
</style>